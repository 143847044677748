import React from "react";
import styles from './FabricArticles.module.scss';


const FabricArticles = ({fabric}) => {
    let types = fabric?.mono_fabric_type || [];
    return (
        <>
            <div className={styles.articles}>
                <button className={types.length > 0 && types.includes('A') ? styles.articleActive : styles.article}>
                    <span>A: {fabric?.fabric_a.name}</span>
                </button>
                <button className={types.length > 0 && types.includes('B') ? styles.articleActive : styles.article}>
                    <span>B: {fabric?.fabric_b.name}</span>
                </button>
            </div>
        </>
    )
}

export default FabricArticles;