import React from "react";
import styles from './FabricOptions.module.scss';
import imgPlaceholder from "../../../../assets/icons/typePlaceholder.svg";

const FabricOptions = ({options}) => {
    return (
        <div className={styles.options}>
            <div className={styles.options__row}>
                {options.map((item) => (
                    item.img ? (
                        <div className={styles.options__itemWrap} key={item.name+Math.random()}>
                            <img src={item.img} alt="" className={styles.options__itemImg}/>
                            <p>{item.name}</p>
                        </div>
                    ) : (
                        <div className={styles.options__itemWrap} key={item.name+Math.random()}>
                            <div className={styles.options__placeholder}>
                                <img src={imgPlaceholder} alt=""/>
                            </div>
                            <p>{item.name}</p>
                        </div>
                    )
                ))}
            </div>
        </div>
    )
}

export default FabricOptions