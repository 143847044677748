import React, {useState} from "react";
import styles from './FabricTypeFabrics.module.scss';
import FabricTypeItem from "./FabricTypeItem";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {setFabricsList, setIsActivePopup, setIsLoading} from "../../../../../store/main-slice";
import GlobalPopup from "../../../../Common/GlobalPopup/GlobalPopup";
import FabricTypePopup from "../FabricTypePopup/FabricTypePopup";
import {getAccessToken, getFabrics} from "../../../../../api/api";
import Preloader from "../../../../Common/Preloader/Preloader";
import {getIsLoading} from "../../../../../store/selectors";

const FabricTypeFabrics = ({fabricTypes, setFabric}) => {
    const [list, setList] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fabricId, setFabricID] = useState(null);
    const [showFabricPopup, setShowFabricPopup] = useState(false);
    const [chosenFabric, setChosenFabric] = useState('');
    const dispatch = useAppDispatch();

    const handlePaginationChange = (event, value) => {
        setPage(value);
        window.scrollTo({top: 0, behavior: 'auto'});
        getAccessToken().then((token) => {
            if (searchValue.length > 0) {
                getFabrics(token, value, [fabricId], searchValue).then((response) => {
                    if (response?.fabrics) {
                        setList(response.fabrics);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                })
            } else {
                getFabrics(token, value, [fabricId]).then((response) => {
                    if (response?.fabrics) {
                        setList(response.fabrics);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                }).catch(err => {
                    console.error("Error fetching fabrics on page change: ", err);
                })
            }
        })
    };

    const openFabricPopup = (name) => {
        setShowFabricPopup(true);
        setChosenFabric(name);
        dispatch(setIsActivePopup(true));
    }

    const closeFabricPopup = () => {
        dispatch(setIsActivePopup(false));
        setShowFabricPopup(false);
        setChosenFabric('');
        setList(null);
        setSearchValue('')
    }

    const onSendFilters = (id) => {
        setTimeout(() => {
            getAccessToken().then((token) => {
                getFabrics(token, 1, [id]).then((response) => {
                    if (response?.fabrics) {
                        setList(response.fabrics);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                })
            })
        },)
    }

    return (
        <div className={styles.fabric}>
            <div className={styles.fabric__row}>
                {fabricTypes.map((item) => <FabricTypeItem key={item.name} item={item} onClick={() => {
                    openFabricPopup(item.name);
                    onSendFilters(item.id);
                    setFabricID([item.id]);
                }}/>)}
            </div>
            {showFabricPopup && fabricId && (
                <GlobalPopup topPopup title={`${chosenFabric}:`} onClose={() => closeFabricPopup()}>
                    <FabricTypePopup searchValue={searchValue} setSearchValue={setSearchValue} page={page} setList={setList}
                                     setPage={setPage} setTotalPages={setTotalPages} filter={fabricId} list={list}
                                     handlePaginationChange={handlePaginationChange} totalPages={totalPages} setFabric={setFabric} closeFabricPopup={closeFabricPopup}/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default FabricTypeFabrics