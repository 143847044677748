import React from "react";
import {Route, Routes} from "react-router-dom";
import Main from "./Main/Main";
import MainMenu from "../Navigations/MainMenu";
import Fabrics from "./Fabrics/Fabrics";
import Fabric from "../UserPage/Fabric";
import Products from "./Products/Products";
import DeliveryUKR from "./DeliveryUKR/DeliveryUKR";
import OrderStatus from "./OrderStatus/OrderStatus";
import Calculator from "./Calculator/Calculator";
import OrderWrapper from "./OrderCreate/OrderWrapper";
import DeliveryNPWrapper from "./DeliveryNP/DeliveryNPWrapper";
import Storage from "./Storage/Storage";

const AuthorizedPage = () => {
    return (
        <>
            <Routes>
                <Route path={'/*'} element={<Main />} />
                <Route path={'/fabrics/'} element={<Fabrics />} />
                <Route path={'/storage/'} element={<Storage />} />
                <Route path={'/order/*'} element={<OrderWrapper />} />
                <Route path={'/order/:id/*'} element={<OrderWrapper />} />
                <Route path={'/products/'} element={<Products />} />
                <Route path={'/deliveryNP/'} element={<DeliveryNPWrapper />} />
                <Route path={'/deliveryUKR/'} element={<DeliveryUKR />} />
                <Route path={'/order_status/'} element={<OrderStatus />} />
                <Route path={'/calculator/'} element={<Calculator />} />

                <Route path={'/user_fabric/*'} element={<Fabric/>} />
            </Routes>
            <MainMenu />
        </>
    )
}

export default AuthorizedPage;