import React, {useEffect, useState} from "react";
import styles from './Calculator.module.scss';
import FabricType from "./FabricType/FabricType";
import FabricSize from "./FabricSize/FabricSize";
import FabricOptions from "./FabricOptions/FabricOptions";
import FabricSet from "./FabricSet/FabricSet";
import CalcButton from "./CalcButton/CalcButton";
import FabricPrice from "./FabricPrice/FabricPrice";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import CommentPopup from "./Popups/CommentPopup/CommentPopup";
import OptionPopup from "./Popups/OptionPopup/OptionPopup";
import {getAccessToken, getFabricTemplate} from "../../../api/api";

const Calculator = () => {
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [showOptionPopup, setShowOptionPopup] = useState(false);
    const [template, setTemplate] = useState(null);
    const [size, setSize] = useState(null);
    const [fabric, setFabric] = useState(null);
    const dispatch = useAppDispatch();
    const [product, setProduct] = useState(null)

    console.log(product)

    useEffect(() => {
        if (fabric && size) {
            getAccessToken().then((token) => {
                getFabricTemplate(token, fabric.id, size.id).then((resp) => {
                    if (resp && resp.components) {
                        setProduct(resp)
                    }
                })
            })
        }

    }, [fabric, size])

    const calcSave = () => {
        console.log('calc save');
    }

    const openCommentPopup = () => {
        setShowCommentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const openOptionPopup = () => {
        setShowOptionPopup(true);
        dispatch(setIsActivePopup(true));
    }

    return (
        <div className={styles.calculator}>
            <h2>Тип тканини</h2>
            <FabricType setTemplate={setTemplate} fabric={fabric} setFabric={setFabric}/>
            <h2>Розмір комплекту</h2>
            <FabricSize kitTemplateSizes={template?.kit_template_size} setKitSize={setSize}/>
            {product && product.components && (
                <h2 className={styles.setTitle}>Комплект</h2>
            )}
            {product && product.components && (
                <FabricSet openCommentPopup={openCommentPopup} openOptionPopup={openOptionPopup} components={product.components}/>
            )}
            {product && product.kit_options && (
                <h2>Опції</h2>
            )}
            {product && product.kit_options && (
                <FabricOptions options={product.kit_options} />
            )}
            <CalcButton action={calcSave}/>
            {product && product.price && (
                <FabricPrice price={product.price}/>
            )}
            {showCommentPopup && (
                <GlobalPopup topPopup title={'Коментар'} onClose={() => setShowCommentPopup(false)}>
                    <CommentPopup/>
                </GlobalPopup>
            )}
            {showOptionPopup && (
                <GlobalPopup topPopup title={'Опції наволочки:'} onClose={() => setShowOptionPopup(false)}>
                    <OptionPopup/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default Calculator;