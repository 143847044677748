import React from "react";
import SearchFabrics from "../../../../Common/SearchFabrics/SearchFabrics";
import {Pagination} from "@mui/material";
import FabricsContent from "../../../Fabrics/FabricsContent/FabricsContent";
import styles from "../../../Fabrics/Fabrics.module.scss";

const FabricTypePopup = ({searchValue, setSearchValue, setPage, setTotalPages, page, filter, handlePaginationChange, totalPages, list, setList, setFabric, closeFabricPopup}) => {
    return (
        <div>
            <SearchFabrics searchValue={searchValue} setSearchValue={setSearchValue} setPage={setPage} setTotalPages={setTotalPages}
                           page={page} filter={filter} isCalculator setList={setList}/>
            {Array.isArray(list) && list.length > 0 ? (
                <FabricsContent isCalculator fabricsAll={list} totalPages={totalPages} setFabric={setFabric} closeFabricPopup={closeFabricPopup}/>
            ) : (
                <p className={styles.warning}>Очікуємо доступні тканини</p>
            )}
            {totalPages > 1 && (
                <Pagination count={totalPages} page={page} siblingCount={1} boundaryCount={1} hidePrevButton hideNextButton onChange={handlePaginationChange} />
            )}
        </div>
    )
}

export default FabricTypePopup