import React, {useEffect, useState} from "react";
import styles from './StoragePopup.module.scss';
import searchIcon from "../../../../assets/icons/search.svg";
import StorageItem from "../../../Common/StorageItem/StorageItem";
import {getAccessToken, getStorageProducts} from "../../../../api/api";
import {Pagination} from "@mui/material";

const StoragePopup = ({setOrder}) => {

    const [storageList, setStorageList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(3);

    useEffect(() => {
        if (storageList && storageList.length === 0) {
            getAccessToken().then((token) => {
                getStorageProducts(token, 1).then((res) => {
                    if (res?.items && res.items.length > 0) {
                        setStorageList(res.items)
                    }

                    if (res?.total_pages) {
                        setTotalPages(res.total_pages)
                    }
                })
            })
        }
    }, [])

    const handlePaginationChange = (event, value) => {
        setPage(value);
        window.scrollTo({top: 0, behavior: 'auto'});
        console.log(value);
        getAccessToken().then((token) => {
            getStorageProducts(token, value).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setStorageList(res.items)
                }
            })
        })

    }

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'}/>
                <img src={searchIcon} alt=""/>
            </div>
            <div className={styles.storage__list}>
                {storageList && storageList.length > 0 && storageList.map(({name, quantity, image, size, color, id}, index) =>
                    <StorageItem key={index} name={name}
                                 quantity={quantity}
                                 image={image} size={size}
                                 id={id} color={color} setOrder={setOrder}
                    />)}
                <Pagination count={totalPages} page={page} siblingCount={1} boundaryCount={1} hidePrevButton
                            hideNextButton onChange={handlePaginationChange}/>
            </div>
        </div>
    )
}

export default StoragePopup;