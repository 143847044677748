import React from "react";
import styles from '../ProductItem.module.scss';
import FabricSlider from "../../../UserPage/FabricSlider";
import ImgPlaceholderIcon from "../../../../assets/icons/placeholderIcon.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../../assets/icons/copy4.svg";
import IconWarning from "../../../../assets/img/warning.svg";

const OpenItem = ({product}) => {

    if (!product) {
        return <div className={styles.notFound}>Продукт не знайдено</div>;
    }

    let {images = [], name, size, color, price, quantity} = product;

    let imageArray = [];

    if (!Array.isArray(images)) {
        imageArray.push(images);
    } else {
        imageArray = images;
    }

    /*let textareaValue = content.join('\n\n');*/

    return (
        <React.Fragment>
            {imageArray.length > 1 ? <FabricSlider images={imageArray}/> :
                <div className={styles.imgPlaceholder}>
                    <img src={imageArray.length && imageArray[0] ? imageArray[0] : ImgPlaceholderIcon}
                         className={!imageArray.length || !imageArray[0] ? styles.placeholderImg : styles.mainImg}
                         alt=""/>
                    <CopyToClipboard text={'COPY'}>
                        <button>
                            <img src={copyIcon} alt=""/>
                        </button>
                    </CopyToClipboard>
                </div>}
            <div className={imageArray.length > 1 ? `${styles.inputRow} ${styles.withSlider}` : styles.inputRow}>
                <div>
                    <label htmlFor="product_input1">Назва</label>
                    <div className={styles.inputWrap}>
                        <input type="text" id="product_input1" value={name} readOnly/>
                        {!quantity && <img src={IconWarning} alt=""/>}
                    </div>
                </div>
                <div>
                    <label htmlFor="product_input2">Тип</label>
                    <input type="text" id="product_input2" value="Товар зі складу" readOnly/>
                </div>
            </div>
            {!quantity && <p className={styles.warning}>Тканини немає в наявності</p>}
            {size && <div className={styles.textareaWrap}>
                <label>Розмір</label>
                <textarea readOnly value={`${size?.width}x${size?.length}`}></textarea>
            </div>}
            {color && <div>
                <label htmlFor="product_options">Колір</label>
                <input type="text" id="product_options" value={color?.name} readOnly/>
            </div>}
            <div className={styles.changeRow}>
                <button className={styles.changeBtn}><span>Змінити товар</span></button>
                <span>{price} грн</span>
            </div>
        </React.Fragment>
    )
}

export default OpenItem;