import React, {useEffect, useState} from "react";
import styles from './Products.module.scss';
import ProductActions from "./ProductActions/ProductActions";
import DiscountField from "../OrderCreate/DiscountField";
import {getAccessToken, getCartOrder, getOrderInfo, updatePayment} from "../../../api/api";
import {useAppSelector} from "../../../hooks/redux";
import {getLastOrder} from "../../../store/selectors";
import CartProducts from "./CartProducts/CartProducts";
import TotalPrice from "./TotalPrice/TotalPrice";

const Products = () => {
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [discountType, setDiscountType] = useState('');
    const [percentInputValue, setPercentInputValue] = useState(0);
    const [UAHInputValue, setUAHInputValue] = useState(0);
    const [popup, setPopup] = useState(null);
    const lastOrderId = useAppSelector(getLastOrder);

    useEffect(() => {

        getAccessToken().then((token) => {
            getCartOrder(token, lastOrderId).then((response) => {
                if (response && response.length > 0) {
                    setOrder(response[0].order);
                    setWarehouseItems(response);
                } else {
                    getOrderInfo(token, lastOrderId).then((response) => {
                        if (response && response.id) {
                            setOrder(response);
                        }
                    })
                }
            })
        });
    }, [])

    useEffect(() => {
        if (order?.payment) {
            const {discount_type, discount_amount} = order.payment;
            if (discountType !== discount_type) {
                setDiscountType(discount_type);
                if (discount_type === 'VAL' && discount_amount) {
                    setUAHInputValue(discount_amount)
                } else if (discount_type === 'PERC' && discount_amount) {
                    setPercentInputValue(discount_amount);
                }
            }
        }
    }, [order])

    return (
        <div className={styles.products}>
            <h2 className={styles.products__title}>Товар</h2>
            {warehouseItems && (
                <CartProducts items={warehouseItems}/>
            )}
            <ProductActions order={order} popup={popup} setPopup={setPopup} setOrder={setWarehouseItems}/>
            <TotalPrice
                price={order?.payment?.amount}
                discountType={discountType}
                discountValue={discountType === 'PERC' ? percentInputValue : UAHInputValue}
            />
            {order?.payment?.discount_amount > 0 && (
                <DiscountField
                    percentInputValue={percentInputValue}
                    setPercentInputValue={setPercentInputValue}
                    UAHInputValue={UAHInputValue}
                    setUAHInputValue={setUAHInputValue}
                    discountType={discountType}
                    setDiscountType={setDiscountType}
                    popup={popup}
                    setPopup={setPopup}
                    orderId={order?.id}
                    getAccessToken={getAccessToken}
                    updatePayment={updatePayment}
                />
            )}
        </div>
    )
}

export default Products;