import React from "react";
import styles from './CalcButton.module.scss';

const CalcButton = ({action, inPopup}) => {
    return (
        <a className={`${styles.btn} ${inPopup ? styles.inPopup : ""}`} onClick={() => action()}>
            <span>Зберегти</span>
        </a>
    )
}

export default CalcButton;