import React from "react";
import styles from './PopupCheckboxes.module.scss';

const PopupCheckboxes = ({values, setValues, chooseList, isRadio = false}) => {

    const handleChange = (value) => {
        if (values.length) {
            const isItemValue = values.find((item) => item === value);

            if (isItemValue) {
                const newArray = values.filter((item) => item !== value);
                setValues([...newArray]);
            } else {
                setValues([...values, value])
            }
        } else {
            const newArray = [value];
            setValues(newArray)
        }
    }

    const setItem = (value) => {
        setValues(value);
    }


    return (
        <div className={styles.popupCheckboxes}>
            {chooseList && (
                chooseList.map((item, index) => (
                    <div key={index} className={styles.customCheckbox}>
                        <input name={isRadio ? 'radioField' : null} type={isRadio ? 'radio' : 'checkbox'}
                               // onChange={() => isRadio ? setItem(item) : handleChange(item)}
                               onChange={() => isRadio ? setItem(item) : handleChange(item)}
                               checked={isRadio ? values === item.value || values === item.title : values?.find(element => element.value === item.value)} />
                        <span/>
                        <p>{item.title}</p>
                    </div>
                ))
            )}
        </div>
    )
}

export default PopupCheckboxes;