import React, {useEffect, useState} from "react";
import styles from './Price.module.scss'

const PriceInfo = ({order}) => {
    const [price, setPrice] = useState(0)

    useEffect(() => {
        if (order?.payment) {
            const discountAmount = order.payment.discount_type === 'PERC'
                ? order.payment.amount - ((order.payment.amount * order.payment.discount_amount) / 100)
                : order.payment.amount - order.payment.discount_amount;
            setPrice(discountAmount);
        }
    }, [order]);

    return (
        <div className={styles.price}>
            {order && order.payment && order.payment.discount_amount > 0 && (
                <p className={styles.oldPrice}>{order.payment.amount} грн</p>
            )}
            <div className={styles.price__info}>
                <p>Ціна замовлення:</p>
                <span className={styles.price__number}>{price} грн</span>
            </div>
            {order && order.payment && order.payment.discount_amount > 0 && (
                <div className={styles.discount}>
                    <>
                        <p>Знижка: {order.payment.discount_type === 'PERC' && <span className={styles.discount__percent}>{order.payment.discount_amount}%</span>}</p>
                        <span className={styles.discount__number}>
                            -{order.payment.discount_type === 'VAL' ? order.payment.discount_amount : (order.payment.amount * order.payment.discount_amount) / 100} грн
                        </span>
                    </>
                </div>
            )}
        </div>
    )
}

export default PriceInfo;