import React from "react";
import styles from './OptionPopup.module.scss';
import imgPlaceholder from "../../../../../assets/icons/typePlaceholder.svg";
import plus from "../../../../../assets/icons/plus.svg";

const OptionPopup = () => {

    let allOptions = [{name:'Стандарт'}, {name:'З вушками'}, {name:'Двостороння'}, {name:'Замочок'}];

    return (
        <div className={styles.popup}>
            {allOptions.map((item) => (
                item.img ? (
                    <div className={styles.options__row} key={item.name + Math.random()}>
                        <div className={styles.options__itemWrap}>
                            <img src={item.img} alt="" className={styles.options__itemImg}/>
                            <p>{item.name}</p>
                        </div>
                        <div className={styles.addWrap}>
                            <button>
                                <img src={plus} alt=""/>
                            </button>
                            <span>Додати</span>
                        </div>
                    </div>
                ) : (
                    <div className={styles.options__row} key={item.name + Math.random()}>
                        <div className={styles.options__itemWrap}>
                            <div className={styles.options__placeholder}>
                                <img src={imgPlaceholder} alt=""/>
                            </div>
                            <p>{item.name}</p>
                        </div>
                        <div className={styles.addWrap}>
                            <button>
                                <img src={plus} alt=""/>
                            </button>
                            <span>Додати</span>
                        </div>
                    </div>
                )
            ))}
        </div>
    )
}

export default OptionPopup