import React, {useEffect, useState} from "react";
import styles from './OrderStatus.module.scss';
import OrderInfoBlock from "../../Common/OrderInfoBlock/OrderInfoBlock";
import Img from "../../../assets/img/slide.png";
import ProductItem from "../../Common/ProductItem/ProductItem";
import OrderActions from "./OrderActions";
import {getAccessToken, getCartOrder, getDeliverNP, getOrderInfo} from "../../../api/api";
import {useAppSelector} from "../../../hooks/redux";
import {getLastOrder} from "../../../store/selectors";
import PriceInfo from "../../ClientPage/Status/Price/Price";

const OrderStatus = () => {
    const lastOrderId = useAppSelector(getLastOrder);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const invoice = 'ТТН 89343473647382487';
    const link = '#';
    const orderStatus = 'Очікує відправки';
    const totalPrice = '8000';


    useEffect(() => {

        getAccessToken().then((token) => {
            getCartOrder(token, lastOrderId).then((response) => {
                if (response && response.length > 0) {
                    setOrder(response[0].order);
                    setWarehouseItems(response);
                } else {
                    getOrderInfo(token, lastOrderId).then((response) => {
                        if (response && response.id) {
                            setOrder(response);
                        }
                    })
                }
            })
            getDeliverNP(token, lastOrderId).then((result) => {
                if (result && result.length > 0) {
                    setDeliveryInfo(result[0])
                }
            })
        });
    }, [])


    return (
        <div className={styles.orderStatus}>
            {order && order.id && (
                <h2>Замовлення № {order.id}</h2>
            )}
            {order && (
                <OrderInfoBlock invoice={invoice} order={order} deliveryInfo={deliveryInfo}/>
            )}
            <div className={styles.statusBar}>
                <span>{orderStatus}</span>
            </div>
            {warehouseItems && warehouseItems.length > 0 && warehouseItems.map((el, index) => <ProductItem
                product={el?.warehouse_item} key={index}/>)}
            <OrderActions/>
            <PriceInfo order={order}/>
            <br/>
            <br/>
        </div>
    )
}

export default OrderStatus;