import React from "react";
import styles from './CommentPopup.module.scss';
import FabricSet from "../../FabricSet/FabricSet";
import CalcButton from "../../CalcButton/CalcButton";

const CommentPopup = () => {

    const saveComment = () => {
        console.log('Save comment');
    }

    return (
        <div className={styles.popup}>
           <FabricSet inPopup/>
            <div className={styles.textBlock}>
                <p>У той час якийсь безіменний друкар створив велику колекцію розмірів і форм шрифтів, використовуючи Lorem Ipsum для роздруківки зразків. Lorem Ipsum не тільки успішно пережив без помітних змін п'ять століть, а й переступив в електронний дизайн. Його популяризації в новий час сприяли публікація аркушів Letraset зі зразками Lorem Ipsum у 60-х роках і, в недавніший час, програми електронного верстання на кшталт Aldus PageMaker, у шаблонах яких використовується Lorem Ipsum.</p>
            </div>
            <CalcButton action={saveComment} inPopup/>
        </div>
    )
}

export default CommentPopup