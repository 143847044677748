export const getBaseUrl = 'https://panel.egodevelopment.pp.ua'

export const getIsAuth = (state) => state.account.isAuth;
export const getUser = (state) => state.account.user;
export const getIsLoading = (state) => state.main.isLoading

export const getIsActivePopup = (state) => state.main.isActivePopup;
export const getOrderList = (state) => state.main.orders;
export const getFabricsAll = (state) => state.main.fabricsAll;
export const getLastOrder = (state) => state.main.lastOrder;

export const getUserProductId = (state) => state.main.userProduct.id;
export const getUserProductImages = (state) => state.main.userProduct.images;

