import React from "react";
import styles from '../ProductItem.module.scss';
import ImgPlaceholder from "../../../../assets/img/placeholder.svg";

const ClosedItem = ({ product = {} }) => {

    let { price = 'Не доступно', name = 'Невідомий продукт', images = [] } = product;

    let imageArray = [];

    if (!Array.isArray(images)) {
        imageArray.push(images);
    } else {
        imageArray = images;
    }

    return (
        <React.Fragment>
            <div className={styles.closedWrap}>
                <img src={!imageArray[0]? ImgPlaceholder:imageArray[0]} alt=""/>
                <div>
                    <p className={styles.closedWrap__title}>{name}</p>
                    <p>{price}</p>
                </div>

            </div>
        </React.Fragment>
    )
}

export default ClosedItem;