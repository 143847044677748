import React from "react";
import ProductItem from "../../../Common/ProductItem/ProductItem";
import Img from "../../../../assets/img/slide.png";


const CartProducts = ({items}) => {

    return (
        <div>
            {items && items.length > 0 && items.map((el, index) => <ProductItem product={el?.warehouse_item} key={index}/>)}
        </div>
    )
}

export default CartProducts;