import React, {useEffect, useState} from "react";
import styles from './FabricType.module.scss';
import FabricTypeFabrics from "./FabricTypeFabrics/FabricTypeFabrics";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {getLastOrder} from "../../../../store/selectors";
import {createProduct, getAccessToken} from "../../../../api/api";
import FabricArticles from "./FabricArticles/FabricArticles";

const FabricType = ({setTemplate, fabric, setFabric}) => {

    const lastOrderId = useAppSelector(getLastOrder);
    const [fabrics, setFabrics] = useState(null);

    useEffect(() => {
        getAccessToken().then((token) => {
            createProduct(token).then((result) => {
                if (result && result.fabric_types) {
                    setTemplate(result)
                    const newArray = result.fabric_types.map((item) =>  {
                        return (
                            {
                                name: item.type,
                                img: '',
                                id: item.id,
                            }

                        )
                    });
                    setFabrics(newArray)
                }
            })
        })
    }, [])

    return (
        <div className={styles.type}>
            <h3>Пара тканин</h3>
            <FabricArticles fabric={fabric} />
            {fabrics && (
                <FabricTypeFabrics fabricTypes={fabrics} setFabric={setFabric}/>
            )}
        </div>
    )
}

export default FabricType