import React from "react";
import ProductItem from "../../../Common/ProductItem/ProductItem";


const List = ({products}) => {

    return (
        <div>
            <h3>Товари в замовленні</h3>
            <div>
                {products && products.length > 0 && products.map((el, index) => <ProductItem product={el} key={index}/>)}
            </div>
        </div>
    )
}

export default List;